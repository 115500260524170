/* eslint-disable arrow-body-style */
import React from 'react'

// Images
// import blueBedroom from 'img/snel_blue_bedroom.svg'
// import blueHouse from 'img/snel_blue_house.svg'
import blueOutsideSize from 'img/snel_blue_outside_size.svg'
import blueInsideSize from 'img/snel_blue_inside_size.svg'
// import blueBathroom from 'img/snel_blue_bathroom.svg'
import blueYear from 'img/snel_blue_year.svg'

// Components
import { SecondHeader, Paragraph } from 'components/StyledComponents'
import ExtraButtons from 'components/Aanbod/ExtraButtons'

// Third Party
import styled from 'styled-components'

const SpecificatiesWrapper = styled.div``

const SpecificatiesContainer = styled.div`
  width: 100%;
  min-height: 184px;
`

const Spec = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;

  ${Paragraph} {
    ::first-letter {
      text-transform: uppercase;
    }
  }

  @media (max-width: 1199px) {
    padding-left: 0;
    padding-right: 0;
  }

  @media (max-width: 340px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding-top: 7px;
    padding-bottom: 7px;
  }
`

const BogSpecificaties = ({ setOpen, data, className }) => {
  return (
    <SpecificatiesWrapper className={`${className ? `${className}` : ``}`}>
      <SpecificatiesContainer className="position-relative color-background-secondary">
        <div className="d-flex justify-content-lg-start px-4 pt-4">
          <SecondHeader className="font-size-xm font-weight-xl text-uppercase">Specificaties</SecondHeader>
        </div>
        <div className="d-flex flex-wrap justify-content-lg-start px-4 pt-4">
          {data.recapBog.bedrijfshalOppervlakte !== "Onbekend" && (
            <Spec className="col-6 pb-2 d-flex align-items-center">
              <img className="pr-3" src={blueOutsideSize} alt="" />
              <Paragraph>{`${data.recapBog.bedrijfshalOppervlakte} m²`}</Paragraph>
            </Spec>
          )}
          {data.recapBog.kantoorOppervlakte !== "Onbekend" && (
            <Spec className="col-6 pb-2 d-flex align-items-center">
              <img className="pr-3" src={blueInsideSize} alt="" />
              <Paragraph>{`${data.recapBog.kantoorOppervlakte} m²`}</Paragraph>
            </Spec>
          )}
          {data.recapBog.buildYear !== "Onbekend" && (
            <Spec className="col-6 pb-2 d-flex align-items-center">
              <img className="pr-3" src={blueYear} alt="" />
              <Paragraph>{`bouwjaar ${data.recapBog.buildYear}`}</Paragraph>
            </Spec>
          )}
          {data.recapBog.ligging !== "Onbekend" && data.recapBog.ligging !== null && (
            <Spec className="col-6 pb-2 d-flex align-items-center">
              <img className="pr-3" src={blueOutsideSize} alt="" />
              <Paragraph>{`${data.recapBog.ligging.toLowerCase().replace(/_/g, ' ')}`}</Paragraph>
            </Spec>
          )}
        </div>
        <div className="d-flex flex-wrap justify-content-center px-4 pb-5 pt-4">
          {/* <ExtraButtons setOpen={setOpen} data={data} className="" /> */}
        </div>
      </SpecificatiesContainer>
    </SpecificatiesWrapper>
  )
}

export default BogSpecificaties