/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import Img from 'gatsby-image'

// Styles
import 'lightbox-react/style.css'

// Third Party
import styled from 'styled-components'
import Slider from 'react-slick'
import Lightbox from 'lightbox-react'
import { size } from 'lodash'


const AanbodImagesWrapper = styled.div``

const BigImg = styled.div`
  width: 100%;
  height: 418px;
  background-color: ${(props) => props.theme.color.secondary};

  @media (max-width: 575px) {
    height: 390px;
  }

  @media (max-width: 550px) {
    height: 350px;
  }

  @media (max-width: 475px) {
    height: 300px;
  }

  @media (max-width: 325px) {
    height: 250px;
  }
`

const SmallImg = styled.div`
  width: 100%;
  height: 103px;
  background-color: ${(props) => props.theme.color.secondary};
  transition: transform 0.25s ease;
  cursor: pointer;

  :hover {
    transform: scale(1.15);
  }
`

const StyledSlider = styled(Slider)`
  .slick-list {
    overflow: hidden;
    margin: 0 -2px;
  }

  .slick-slide {
    margin: 0 2px;
  }

  .slick-track {
    display: flex;
  }
`

const ImgContainer = styled.div`
  overflow: hidden;
`

const StyledImg = styled.img`
  height: 100%;
  width: 100%;
  cursor: pointer;
  object-fit: cover;
`

const StyledNormalImg = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`

const AanbodImages = ({ data, className }) => {
  const [mediaData, setMediaData] = useState({
    photoIndex: 0,
    isVisible: false
  })

  const otherImages = JSON.parse(data.recapBog.gallery)

  return (
    <AanbodImagesWrapper className={`${className ? `${className}` : ``}`}>
      <BigImg role="button" tabIndex="0" onKeyPress={() => { }} onClick={() => setMediaData({ photoIndex: 0, isVisible: true })} className="color-background-secondary d-flex justify-content-center align-items-center">
        <StyledImg src={data.recapBog.image} alt="" />
      </BigImg>
      <StyledSlider
        className="d-flex py-3 position-relative"
        infinite
        speed={500}
        slidesToShow={4}
        slidesToScroll={1}
        autoplay
        autoplaySpeed={4000}
        prevArrow={<div />}
        nextArrow={<div />}
        responsive={[
          {
            breakpoint: 1199,
            settings: {
              slidesToShow: 3
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 2
            }
          },
          {
            breakpoint: 325,
            settings: {
              slidesToShow: 1
            }
          }
        ]}
      >
        {otherImages.map((image, index) => (
          <ImgContainer role="button" tabIndex="0" onKeyPress={() => { }} onClick={() => setMediaData({ photoIndex: index, isVisible: true })} key={index}>
            <SmallImg className="color-background-secondary d-flex justify-content-center align-items-center" key={index}>
              <StyledNormalImg loading="lazy" src={image} alt="" />
            </SmallImg>
          </ImgContainer>
        ))}
      </StyledSlider>

      {mediaData.isVisible && (
        <Lightbox
          mainSrc={otherImages[mediaData.photoIndex]}
          nextSrc={otherImages[(mediaData.photoIndex + 1) % size(otherImages)]}
          prevSrc={
            otherImages[(mediaData.photoIndex + size(otherImages) - 1) % size(otherImages)]
          }
          onCloseRequest={() =>
            setMediaData({ ...mediaData, ...{ isVisible: false } })}
          onMovePrevRequest={() =>
            setMediaData({
              ...mediaData,
              ...{
                photoIndex:
                  (mediaData.photoIndex + size(otherImages) - 1) % size(otherImages),
              },
            })}
          onMoveNextRequest={() =>
            setMediaData({
              ...mediaData,
              ...{ photoIndex: (mediaData.photoIndex + 1) % size(otherImages) },
            })}
        />
      )}
    </AanbodImagesWrapper>
  )
}

export default AanbodImages