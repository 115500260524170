import React, { useState } from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/SEO'
import Layout from 'components/Layout'
import CustomBreadCrumb from 'components/Common/Breadcrumb'
import BogContent from 'components/Common/BogContent'
// import Share from 'components/Aanbod/Share'
import Preview from 'components/Home/Preview'
import AanbodImagesBog from 'components/Aanbod/AanbodImagesBog'
import BogSpecificaties from 'components/Aanbod/BogSpecificaties'
import { Paragraph, SecondHeader } from 'components/StyledComponents'
import ContactModal from 'components/Common/ContactModal'
// import Content from 'components/Content'

// Third Party
import styled from 'styled-components'
import parse from 'html-react-parser'
import ShareBog from '../components/Aanbod/ShareBog'
// import ProtoContent from '../components/Diensten/ProtoContent'

const Aanbod = styled.div``

const Separator = styled.div`
  height: 1px;
  width: 100%;
`

const BogDetailTemplate = ({
  data: {
    woning,
    otherBog: { edges: otherBog },
    allBog: { edges: allBog },
  },
  location,
  pageContext,
}) => {
  const [bezIsOpen, setBezOpen] = useState(false)

  const formatter = new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
  })

  const checkKoopConditie = () => {
    let returnString = ''

    if (woning.recapBog.koopconditie[0] !== 'Onbekend') {
      if (woning.recapBog.koopconditie[0] === 'VRIJ_OP_NAAM') {
        returnString = 'v.o.n.'
      } else if (woning.recapBog.koopconditie[0] === 'KOSTEN_KOPER') {
        returnString = 'k.k.'
      } else {
        returnString = 'N/A'
      }
    } else {
      returnString = 'N/A'
    }

    return returnString
  }

  const checkHuurConditie = () => {
    let returnString = ''

    if (woning.recapBog.huurconditie !== 'Onbekend') {
      if (woning.recapBog.huurconditie === 'PER_MAAND') {
        returnString = 'p/m.'
      } else if (woning.recapBog.huurconditie === 'PER_JAAR') {
        returnString = 'p/j.'
      } else {
        returnString = ''
      }
    } else {
      returnString = ''
    }

    return returnString
  }

  return (
    <Layout>
      {/* {() => ( */}
        <>
          <SEO seo={woning.seo} socialImage={woning.recapBog.image} />
          <Aanbod>
            <CustomBreadCrumb
              data={{ title: woning.recapBog.adres }}
              className="py-2"
              pageContext={pageContext}
            />
            <div className="container">
              <div className="row pt-5">
                <div className="col-lg-7 pl-lg-3">
                  <BogContent
                    data={{
                      recapBog: {
                        content: `<h1>${woning.recapBog.address}</h1>`,
                      },
                    }}
                  />
                  <div className="d-flex justify-content-between pb-3">
                    <Paragraph className="font-size-xm">
                      {woning.recapBog.plaats}
                    </Paragraph>
                    {
                      {
                        BESCHIKBAAR: (
                          <Paragraph className="font-size-xm font-weight-xl">{`${
                            woning.recapBog.koop === '1'
                              ? `${formatter.format(
                                  woning.recapBog.koopprijs
                                )},- ${checkKoopConditie()}`
                              : `${formatter.format(
                                  woning.recapBog.price
                                )},- ${checkHuurConditie()}`
                          }`}</Paragraph>
                        ),
                        VERKOCHT: (
                          <Paragraph className="font-size-xm font-weight-xl text-uppercase">
                            Verkocht
                          </Paragraph>
                        ),
                        VERHUURD: (
                          <Paragraph className="font-size-xm font-weight-xl text-uppercase">
                            Verhuurd
                          </Paragraph>
                        ),
                        VERKOCHT_ONDER_VOORBEHOUD: (
                          <Paragraph className="font-size-xm font-weight-xl text-uppercase">
                            Verkocht onder voorbehoud
                          </Paragraph>
                        ),
                        VERHUURD_ONDER_VOORBEHOUD: (
                          <Paragraph className="font-size-xm font-weight-xl text-uppercase">
                            Verhuurd onder voorbehoud
                          </Paragraph>
                        ),
                        ONBEKEND: (
                          <Paragraph className="font-size-xm font-weight-xl text-uppercae">
                            Onbekend
                          </Paragraph>
                        ),
                      }[woning.recapBog.status]
                    }
                  </div>
                  <AanbodImagesBog data={woning} className="py-2" />
                  <Separator className="color-background-main mt-2 mb-2 mb-lg-4" />
                  <BogSpecificaties
                    setOpen={setBezOpen}
                    data={woning}
                    className="pb-5 pt-4 pt-lg-0 d-flex d-lg-none justify-content-center justify-content-lg-end"
                  />
                  <SecondHeader className="font-size-xm font-weight-xl pb-3 text-uppercase">
                    Informatie
                  </SecondHeader>
                  <Paragraph className="w-100">
                    {parse(
                      `<p>${woning.recapBog.description.replace(
                        /(?:\r\n|\r|\n)/g,
                        `<br>`
                      )}</p>`
                    )}
                  </Paragraph>
                </div>
                <div className="col-12 pl-lg-5 pt-5 pt-lg-0 col-lg-5 pr-lg-3">
                  <ShareBog
                    setOpen={setBezOpen}
                    location={location}
                    data={woning}
                    className="pb-5 pt-4 pt-lg-0 justify-content-center justify-content-lg-end"
                  />
                  <BogSpecificaties
                    setOpen={setBezOpen}
                    data={woning}
                    className="pb-5 pt-4 pt-lg-0 d-none d-lg-flex justify-content-center justify-content-lg-end"
                  />
                </div>
              </div>
              <Preview
                bog
                data={otherBog}
                title="Vergelijkbare bedrijfspanden"
                loadMoreButton={false}
                className="py-5 mb-5"
              />
            </div>
            <ContactModal
              currentObject={woning}
              type="bog"
              title="Bezichtiging plannen"
              woningen={allBog}
              id={3}
              modalIsOpen={bezIsOpen}
              setModalOpen={setBezOpen}
            />
          </Aanbod>
        </>
      {/* )} */}
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($databaseId: Int!) {
    woning: wpBog(databaseId: { eq: $databaseId }) {
      slug
      uri
      title
      recapBog {
        zipcode
        woonkamerOppervlakte
        video
        typeOfHouse
        typeHouse
        type
        totaleWoonoppervlakte
        totaleOppervlakte
        status
        publishDate
        province
        price
        openForVisits
        numberSleeprooms
        numberRooms
        location
        koopconditie
        koop
        image
        huurconditie
        huur
        houseId
        gallery
        fieldGroupName
        description
        city
        country
        buildYear
        bathrooms
        address
        ligging
      }
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublisher
        opengraphPublishedTime
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          sourceUrl
        }
        twitterImage {
          sourceUrl
        }
      }
    }
    allBog: allWpBog {
      edges {
        node {
          title
          slug
          databaseId
        }
      }
    }
    otherBog: allWpBog(filter: { databaseId: { ne: $databaseId } }) {
      edges {
        node {
          title
          slug
          databaseId
          recapBog {
            zipcode
            woonkamerOppervlakte
            video
            typeOfHouse
            typeHouse
            type
            totaleWoonoppervlakte
            totaleOppervlakte
            status
            publishDate
            province
            price
            openForVisits
            numberSleeprooms
            numberRooms
            location
            koopconditie
            koop
            image
            huurconditie
            huur
            houseId
            gallery
            fieldGroupName
            description
            city
            country
            buildYear
            bathrooms
            address
            ligging
          }
        }
      }
    }
  }
`

export default BogDetailTemplate
