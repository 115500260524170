/* eslint-disable arrow-body-style */
import React from 'react'

// Components
import CustomButton from 'components/Diensten/CustomButton'
import Title from 'components/Diensten/Title'
import SellingPoints from 'components/Diensten/SellingPoints'

// Third Party
import styled from 'styled-components'
import parse from 'html-react-parser'

const ContentWrapper = styled.div``

const InnerContentContainer = styled.div`
  p {
    line-height: 1.71;
    letter-spacing: -0.19px;
    font-size: ${(props) => props.theme.font.size.sm};
  }

  a {
    p {
      font-size: ${(props) => props.theme.font.size.ms};
    }
  }

  h1 {
    font-size: 30px;
    padding-bottom: 10px;
  }

  h2 {
    font-size: ${(props) => props.theme.font.size.sm};
  }

  strong {
    text-transform: uppercase;
  }
`

const BogContent = ({ data, className }) => {
  return (
    <ContentWrapper className={`${className ? `${className}` : ``}`}>
      <InnerContentContainer>
        {parse(data.recapBog.content, {
          replace: (domNode) => {
            if (domNode.name === 'h3') {
              if (domNode.children.find((o) => o.name === 'a')) {
                if (domNode.children.find((o) => o.name === 'a').attribs.href.indexOf("http://") === 0 || domNode.children.find((o) => o.name === 'a').attribs.href.indexOf("https://") === 0) {
                  return <CustomButton className="py-4 justify-content-sm-start justify-content-center" data={{ url: domNode.children.find((o) => o.name === 'a').attribs.href, title: domNode.children.find((o) => o.name === 'a').children[0] }} proto />
                }
                return <CustomButton className="py-4 justify-content-sm-start justify-content-center" data={{ url: domNode.children.find((o) => o.name === 'a').attribs.href, title: domNode.children.find((o) => o.name === 'a').children[0] }} proto />
              }
            } else if (domNode.name === 'h2') {
              return <Title proto luxe data={{ title: domNode.children[0].data.split("/")[0], sub_text: domNode.children[0].data.split("/")[1] }} />
            } else if (domNode.name === 'h1') {
              return <Title mainheader proto data={{ title: domNode.children[0].data }} />
            } else if (domNode.name === 'ul') {
              return <SellingPoints proto data={domNode.children} className="pl-lg-3 pb-2 pt-1" />
            }
            return true
          }
        })}
      </InnerContentContainer>
    </ContentWrapper>
  )
}

export default BogContent